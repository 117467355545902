<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <SelectorOpciones></SelectorOpciones>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import SelectorOpciones from '../components/ColumnaDerecha/SelectorOpciones.vue';
export default {
    name: 'IndexView',
    components: {
        ColumnaIzquierda,
        SelectorOpciones
    }
}
</script>