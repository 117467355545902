<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <VentanaAjustes></VentanaAjustes>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import VentanaAjustes from '../components/ColumnaDerecha/VentanaAjustes.vue';
export default {
    name: 'SettingsView',
    components: {
        ColumnaIzquierda,
        VentanaAjustes
    }
}
</script>