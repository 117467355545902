import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import IndexView from '@/views/IndexView.vue'
import ValidateView from '@/views/ValidateView.vue'
import CreatePinView from '@/views/CreatePinView.vue'
import ChangePinView from '@/views/ChangePinView.vue'
import SettingsView from '@/views/SettingsView.vue'
import CameraView from '@/views/CameraView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView
  },
  {
    path: '/validate',
    name: 'validate',
    component: ValidateView
  },
  {
    path: '/createpin',
    name: 'createpin',
    component: CreatePinView
  },
  {
    path: '/changepin',
    name: 'changepin',
    component: ChangePinView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/camera',
    name: 'camera',
    component: CameraView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
