import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons from free solid, regular and brand icon sets */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
/* add all free icons to the library */
library.add(fas, far, fab)
fetch('/settings.json')
  .then(response => response.json())
  .then(settings => {
    store.commit('setSettings', settings);
    // Puedes guardar las configuraciones en el contexto global de Vue o en un store como Vuex.
    createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')
  })
  .catch(error => {
    console.error('Error loading settings:', error);
  });
/* create Vue app and register font-awesome-icon globally */

