import Swal from 'sweetalert2';

export function notify(toast, title, text, icon, timer = 3000) {
    Swal.fire({
    title,
    text,
    icon, // Puedes usar 'success', 'error', 'warning', 'info', o 'question'
    timer, // Tiempo que la alerta estará visible (en milisegundos)
    toast,
    timerProgressBar: true,
    position: 'top-right',
    showConfirmButton: false,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
  });
}