<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <CrearPin></CrearPin>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import CrearPin from '../components/ColumnaDerecha/CrearPin.vue';
export default {
    name: 'ChangePinView',
    components: {
        ColumnaIzquierda,
        CrearPin
    }
}
</script>