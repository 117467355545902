<template>
  <el-col id="colizquierda" :xs="24" :md="12" :cols="12">
    <div class="instrucciones" :style="`background-color:${datoscolor.color};`">
      <div class="instrucciones-msg">
        <div class="contenedorContador">
          <h1 :style="`color: ${datoscolor.colortexto}; font-weight: revert;`">{{ hora }}</h1>
          <div v-if="datosemp.nombre"
            style="display: flex; text-align: left; align-items: center; justify-content:space-between; background: rgba(0, 0, 0, 0.26); padding: 10px; border-radius: 10px; color: white; transition: 0.5s; margin-bottom: 10px; min-width: 300px; height: 77px;">
            <span style="display: flex; align-items:center;">
              <img
                :style="`border-radius: 50%; border: 3px solid transparent; outline:3px solid ${calcularColorEstado()}; position:absolute; transition: 1s;`"
                :src="`https://www.gravatar.com/avatar/${datosemp.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                width="50px" />
              <span @click="selectorestado = !selectorestado;"
                :class="`selectorestado ${selectorestado ? 'selected' : ''}`">
                <font-awesome-icon v-if="!selectorestado" :icon="['fas', 'pencil-alt']" />
                <font-awesome-icon v-if="selectorestado" :icon="['fas', 'arrow-left']" />
              </span>
              <div style="display:flex; flex-direction: column; margin-left: 10px;">
                <p v-if="!selectorestado" style="margin: 0px; font-weight: 400; font-size: 1.3rem; margin-bottom: 5px;">
                  Hola, {{ datosemp.nombre.split(' ')[0] }}</p>
                <small v-if="!selectorestado">{{ datosemp.estado }}</small>
                <p v-if="selectorestado" style="margin: 0px; font-weight: 400; font-size: 1.2rem; margin-bottom: 5px;">
                  Estado:
                </p>
                <div v-if="selectorestado" style="display:flex; flex-direction: row;">
                  <span @click="estado = 'Disponible'" class="estado"><font-awesome-icon
                      style="color: #44fc97; font-size:1.6rem;" :icon="['fas', 'circle']" /><span><font-awesome-icon
                        :icon="['far', 'smile']" />
                      Disponible</span></span>
                  <span @click="estado = 'Ausente'" class="estado"><font-awesome-icon
                      style="color: #ffc000; font-size:1.6rem;" :icon="['fas', 'circle']" /><span><font-awesome-icon
                        :icon="['fas', 'door-open']" />
                      Ausente</span></span>
                  <span @click="estado = 'Reunido'" class="estado"><font-awesome-icon
                      style="color: #48dbfb; font-size:1.6rem;" :icon="['fas', 'circle']" /><span><font-awesome-icon
                        :icon="['far', 'handshake']" />
                      Reunión</span></span>
                  <span @click="estado = 'Ocupado'" class="estado"><font-awesome-icon
                      style="color: #ff4757; font-size:1.6rem;" :icon="['fas', 'circle']" /><span><font-awesome-icon
                        :icon="['fas', 'ban']" />
                      Ocupado</span></span>
                </div>
                <!-- <p style="margin: 0px; font-weight: 400; font-size: 0.8rem;">Estado: Trabajando</p> -->
              </div>
            </span>
            <img v-if="centro.selcentro == true" @click="$router.push('/settings')" class="iconoconfig"
              style="max-width: 50px;" src="@/assets/configuracion.svg" />
            <img v-else @click="girarBoton(true)" class="iconosalir" style="max-width: 50px;"
              src="@/assets/cerrar-sesion.svg" />
          </div>
          <div
            style="display: flex; text-align: left; align-items: center; justify-content: center; background: rgba(0, 0, 0, 0.26); padding: 10px; border-radius: 10px; color: white; transition: 0.5s; margin-bottom: 10px; min-width: 300px;">
            <span
              style="font-size: 4rem; margin-right: 10px; display:flex; flex-direction: column; align-items: center;"><b>{{
                obtener('dia') }}</b><b style="font-size: 1.5rem;"></b></span>
            <span style="display:flex; flex-direction: column; justify-content:center;">
              <span style="margin: 5px;"><b>{{ obtener('diasem').charAt(0).toUpperCase() +
                obtener('diasem').slice(1) }}</b></span>
              <span style="margin: 5px;">{{ obtener('mes').charAt(0).toUpperCase() + obtener('mes').slice(1)
                }}</span>
            </span>
            <span v-if="registros && registros.length"
              style="display:flex; flex-direction: column; justify-content:center; margin-left: 30px;">
              <span v-for="registro in registros" :key="registro.hora" style="margin: 5px; font-size: 1.2rem;">
                <b
                  :style="`${registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? 'color: rgb(163, 203, 56);' : ''} ${registro.tipoRegistro == 'Pausa' ? 'color: rgb(249, 202, 36);' : ''} ${registro.tipoRegistro == 'Fin' ? 'color: rgb(255, 71, 87);' : ''}`"><font-awesome-icon
                    :icon="registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? ['fas', 'arrow-right'] : ['fas', 'arrow-left']" /></b>
                <b>{{ registro.hora.split(':')[0] + ':' + registro.hora.split(':')[1] }}</b>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <img v-show="settings.mostrar_logo" id="logoempresa" :src="settings.logo" />
  </el-col>
  <div v-show="settings.cabecera" id="contenedorlogo" :style="`background: ${datoscolor.color}`">
    <img id="logoempresa" :src="settings.logo" @load="calcularColorLogo" />
  </div>
  <span id="version" :style="`color: ${datoscolor.colortexto};`">{{ settings.version }}</span>
</template>

<script>
const moment = require('moment');
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
import { mapState } from 'vuex'
import ColorThief from 'colorthief';
import { notify } from '@/swal';
export default {
  name: 'ColumnaIzquierda',
  data() {
    return {
      centro: {
        selcentro: true,
      },
      selectorestado: false,
      estado: '',
      hora: ''
    }
  },
  mounted() {
    this.calcularColorLogo();
    this.hora = this.obtener('hora');
    this.interval = setInterval(() => {
      this.hora = this.obtener('hora');
    }, 1000);
  },
  computed: mapState([
    'datosemp', 'datoscolor', 'registros', 'settings'
  ]),
  watch: {
    estado() {
      if (this.estado != '') {
        if (this.estado == 'Reunido') {
          this.$prompt('Puedes establecer un comentario, como la ubicación donde celebrarás la reunión.', 'Introduce un comentario', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(({ value }) => {
            this.estado = 'Reunido: ' + value;
            this.cambiarEstado();
          }).catch(() => {
            notify(true, null, 'Se ha cancelado el cambio de estado', 'info');
          });
        } else {
          this.cambiarEstado();
        }
      }
      this.selectorestado = false;
    },
  },
  methods: {
    obtener(tipo) {
      switch (tipo) {
        case 'dia':
          return moment().format('D');
        case 'diasem':
          return moment().format('dddd');
        case 'mes':
          return moment().format('MMMM');
        case 'hora':
          return moment().format('H:mm');
      }
    },
    async cambiarEstado() {
      notify(true, null, 'Cambiando estado', 'info');
      let resp = await fetch(this.settings.api_url + '/api/fichajes/cambiarEstado', {
        headers: {
          "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify({ dni: this.datosemp.dni, estado: this.estado })
      })
      if (resp.status == 200) {
        notify(true, null, 'Tu estado se ha modificado correctamente', 'success');
        this.datosemp.estado = this.estado;
      } else {
        notify(true, null, 'Ha ocurrido un error modificando tu estado', 'error');
      }
    },
    calcularColorEstado() {
      let respuesta = '';
      switch (this.datosemp.estado) {
        case 'Disponible':
          respuesta = '#44fc97';
          break;
        case 'Ausente':
          respuesta = '#ffc000';
          break;
        case 'Reunido':
          respuesta = '#48dbfb';
          break;
        case 'Ocupado':
          respuesta = '#ff4757';
          break;
      }
      if (this.datosemp.estado.includes('Reunido')) {
        respuesta = '#48dbfb';
      }
      return respuesta;
    },
    async calcularColorLogo() {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      let imagen = document.getElementById('logoempresa').src;
      image.src = imagen;
      image.onload = () => {
        const colorThief = new ColorThief();
        const color = colorThief.getColor(image);
        let colortexto = this.darkenColor(color, 40);
        console.log(colortexto);
        this.datoscolor.colorfondobotones = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.2)';
        this.datoscolor.colorbotones = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 1)';
        this.datoscolor.color = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.2)';
        this.datoscolor.colortexto = 'rgb(' + colortexto[0] + ', ' + colortexto[1] + ', ' + colortexto[2] + ')';
      }
    },
    darkenColor(rgb, amount) {
      console.log(rgb);
      const [r, g, b] = rgb;

      // Asegurarse de que los valores estén dentro del rango [0, 255]
      const clamp = (value) => Math.max(0, Math.min(value, 255));

      // Oscurecer los componentes R, G y B por el valor "amount"
      const darkenedR = clamp(r - amount);
      const darkenedG = clamp(g - amount);
      const darkenedB = clamp(b - amount);

      // Devolver el color oscurecido como un array [r, g, b]
      return [darkenedR, darkenedG, darkenedB];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#version {
  opacity: 0;
  color: rgb(0, 107, 28);
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;

  &:hover {
    opacity: 1;
  }
}
#contenedorlogo {
  display: flex;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  justify-content: center;
  transition: 0.5s;
  padding: 20px;
  width: 100%;

  img {
    max-height: 100px;
    max-width: 70%
  }

  @media (min-width: 990px) {
    display: none
  }
}

.iconoconfig {
  border-radius: 50%;
  transition: 0.5s;
  padding: 5px;

  &:hover {
    background: #00000075;
  }
}

#colizquierda {
  position: relative;
  background-size: cover;
  display: flex;
  overflow: hidden;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .instrucciones {
    color: #33438a;
    width: -webkit-fill-available;
    text-align: right;
    padding-bottom: 20px;
    transition: 2s;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 2rem;
      margin: 20px;
      transition: 0.5s;

      @media(min-width: 990px) {
        font-size: 4rem;
      }
    }

    .instrucciones-msg {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      margin: auto;
      position: absolute;
      height: 100vh;
      width: 100%;
      justify-content: center;
    }

    .contenedorContador {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      z-index: 1;

      @media (max-width: 990px) {
        display: none;
      }

      .selectorestado {
        border-radius: 50%;
        border: 3px solid transparent;
        background: rgba(0, 0, 0, 0.5);
        width: 50px;
        height: 50px;
        position: relative;
        opacity: 0;
        transition: 0.3s;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 1;
        }

        &.selected {
          opacity: 1;
        }

        &:active {
          background: rgba(0, 0, 0, 0.5);
        }
      }

      .estado {
        position: relative;
        margin: 5px;

        span {
          position: absolute;
          transform: translate(-50%, 0);
          left: 50%;
          top: 60%;
          margin-top: 10px;
          padding: .55rem 1rem;
          font-size: 1rem;
          font-weight: 500;
          white-space: nowrap;
          color: #000;
          border-radius: 50px;
          background-color: #fff;
          box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
            0 5px 15px rgba(0, 0, 0, 0.07);
          pointer-events: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          opacity: 0;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          span {
            top: 100%;
            opacity: 0.9;
          }

          color: gray;

          &::after {
            position: absolute;
            content: "";
            width: 25.59px;
            height: 25.59px;
            background-color: rgb(0, 0, 0);
            top: 0;
            left: 0;
            border-radius: 50%;
            opacity: 0.3;
          }

          &:active {
            &::after {
              position: absolute;
              content: "";
              width: 25.59px;
              height: 25.59px;
              background-color: rgb(0, 0, 0);
              top: 0;
              left: 0;
              border-radius: 50%;
              opacity: 0.5;
            }
          }
        }
      }

    }
  }

  #logoempresa {
    display: none;
    position: absolute;
    max-width: 40%;
    max-height: 150px;
    left: 20px;
    bottom: 20px;
    transition: 1s;

    @media(min-width: 991px) {
      display: block;
    }
  }

  @media (min-width:990px) {
    height: 100vh;
  }

  @media(max-width: 990px) {
    display: none;
  }
}
</style>