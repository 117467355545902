<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <PinPad></PinPad>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import PinPad from '../components/ColumnaDerecha/PinPad.vue';
export default {
    name: 'LoginView',
    components: {
        ColumnaIzquierda,
        PinPad
    }
}
</script>