<template>
    <ColumnaIzquierda></ColumnaIzquierda>
    <CameraPic></CameraPic>
</template>
<script>
import ColumnaIzquierda from '../components/ColumnaIzquierda/ColumnaIzquierda.vue';
import CameraPic from '../components/ColumnaDerecha/CameraPic.vue';
export default {
    name: 'CameraView',
    components: {
        ColumnaIzquierda,
        CameraPic
    }
}
</script>